@import "../common/variables";

body .terms-and-cond-lbox {
  background: none repeat scroll 0 0 get-color('white');
  min-height: 280px;
  //position: absolute;
  width: 100%;
  max-width: 540px;
  z-index: 1002;
  box-shadow: 0 0 20px 0 #222;
  -webkit-box-shadow: 0 0 20px 0 #222;
  -moz-box-shadow: 0 0 20px 0 #222;
}

.terms-and-cond-lbox .wcs-close-btn {
  right: 40px;
  top: 30px;
}

.terms-and-cond-lbox .maincontent {
  padding: 30px 40px;
}

.terms-and-cond-lbox .maincontent h4 {
  color: get-color('black');
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 20px 0;
}

.terms-and-cond-lbox .maincontent p {
  font-size: 12px;
  color: get-color('grey-3');
  line-height: 16px;
  margin-top: 18px;
}

.terms-and-cond-lbox .maincontent .wcs-four a {
  color: get-color('black');
}

.terms-and-cond-lbox .maincontent .wcs-four .agreeConsent a {
  color: get-color('grey-3');
}

.terms-and-cond-lbox .wcs-compare-item-price {
  text-align: right;

  .rb-btn + .rb-btn {
    margin-left: 20px;
  }
}

.wcs-btn-disable {
  border: 2px solid get-color('grey') !important;
  color: get-color('grey');
}

.wcs-marginTop-30 {
  margin-top: 30px;
}

.clear {
  clear: both;
}

.wcs-padTop-15 {
  padding-top: 15px !important;
}


/*RB-2633*/

.thankyou_agree {
  padding-left: 112px;
}

.thankyou_agree .agreeConsent {
  padding-left: 28px !important;
}

.privacy-policy-agree .wcs-error-message {
  margin-left: 25px !important;
}
